import { FC, useCallback, useContext, useMemo } from "react";
import { ConfigContext } from "@finbackoffice/site-core";
import Dropdown, { DrowdownOptions } from "components/base/dropdown/Dropdown";
import styles from "./odds-format-selector.module.sass";

const OddsFormatSelector: FC = () => {
    const { siteConfigReady, changeOddFormat, siteSupportedOddsFormat, siteCurrentOddFormat } =
        useContext(ConfigContext);

    const getOddsFormat = useMemo((): DrowdownOptions => {
        const result: DrowdownOptions = [];
        siteSupportedOddsFormat?.forEach((format) => {
            result.push({ value: format, label: `oddsFormats_${format}` });
        });

        return result;
    }, [siteSupportedOddsFormat]);

    const onOddsFormatChangeHandler = useCallback(
        (val: string) => {
            changeOddFormat(val);
        },
        [changeOddFormat],
    );

    return (
        <Dropdown
            loading={!siteConfigReady}
            styleClass={styles.oddsDropdown}
            data={getOddsFormat}
            selected={siteCurrentOddFormat}
            onChange={onOddsFormatChangeHandler}
        />
    );
};
export default OddsFormatSelector;
